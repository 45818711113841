import React from 'react'
import alex from '../../../assets/images/story/gorgias/alex-plugaru.png'
import image1 from '../../../assets/images/story/gorgias/screenshot1.png'
import image2 from '../../../assets/images/story/gorgias/screenshot2.png'
import image3 from '../../../assets/images/story/gorgias/screenshot3.png'
import image4 from '../../../assets/images/story/gorgias/screenshot4.png'
import image5 from '../../../assets/images/story/gorgias/screenshot5.png'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'
import QuoteBlock from '../QuoteBlock'

const GorgiasBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <>
            <p>
              Gorgias is building a multi-channel helpdesk integrated with
              e-commerce merchants&apos; back-office. It allows merchants to
              manage all their support from one place It serves 7000+ customers.
            </p>
            <a
              target="\_blank"
              rel="noopener noreferrer"
              href="https://gorgias.com"
            >
              https://gorgias.com
            </a>
          </>
        ),
        location: (
          <p>
            Gorgias was founded in Paris. It has now branches in 6 cities across
            3 continents.
          </p>
        ),
        headcount: <p>200+ employees</p>,
        customers: <p>2021</p>,
      }}
      main={
        <>
          <p>
            Gorgias is building a multi-channel helpdesk integrated with
            e-commerce merchants&apos; back-office. It allows merchants to
            manage all their support from one place. They have been scaling fast
            for the past years and have grown into a 200 person organization
            serving 7000+ customers.
          </p>
          <p>
            One of Gorgias&apos; core values is always to put customers first.
            As building working software for their customers is a core part of
            their mission, a key question for the engineering team is - How to
            improve the flow of delivery so that we can improve our ability to
            meet customer expectations? This is why they wanted to build
            transparency into potential antipatterns and blockers to supercharge
            product development of an already well-performing organization.
          </p>
          <h3>
            Going beyond the aggregate numbers - driving investigation into
            potential problems
          </h3>
          <p>
            There had already been discussions about stale pull requests and the
            difficulty of getting reviews in the Gorgias organization. The team
            had a hypothesis that they could decrease pull request cycle time to
            improve the flow of delivery. This would ultimately mean getting
            closer to customer value faster.
          </p>
          <p>
            Cycle time is a proper measure to describe how well your engine is
            running. However, the team realized that only bringing visibility
            into cycle time as an aggregate metric would not cut it. They would
            need to investigate antipatterns and process bottlenecks behind the
            aggregate number to start driving real improvements. So the first
            step would be to explore the data, beginning with an understanding
            of what all affects cycle time.
          </p>
          <p>Cycle time is influenced by things like:</p>
          <ul>
            <li>What are all the other things we&apos;re working on?</li>
            <li>How do we split our work?</li>
            <li>How do we review the code?</li>
            <li>How well do I know the codebase I&apos;m working on?</li>
            <li>How often do we deploy to production/release our software?</li>
            <li>How much time are we spending on tasks beyond writing code?</li>
          </ul>
          <p>
            The team thoroughly investigated different antipatterns and process
            bottlenecks that could cause disturbance in the flow of delivery,
            ranging from work in progress to CI/CD pipeline.
          </p>
          <p>
            Using Swarmia&apos;s flow insights to their advantage, they were
            able to surface insights that would help them to start improving the
            flow of delivery. It seemed like code reviews were one clear
            bottleneck hindering a swift pull request process. Furthermore, they
            found three key insights about code reviews:
          </p>
          <ol>
            <li>
              In all teams, there were outlier pull requests that were not
              touched for multiple days or even weeks while open.
            </li>
            <ProductScreenshot src={image1} />
            <li>
              Limited to certain teams, long review times were mostly due to
              cross-team reviews taking significantly longer than internal team
              reviews.
            </li>
            <ProductScreenshot src={image2} />
            <li>
              The teams also noticed that they lacked common guidelines for code
              review best practices.
            </li>
          </ol>
          <QuoteBlock
            quote="We used Swarmia's flow insights to validate our
            hypotheses with data and drill down into what is slowing down our
            flow of delivery. This helped us conclude what to improve and
            how."
            name="Alex Plugaru"
            title="Co-Founder, CTO"
            photo={alex}
          />
          <p>
            With more insights about their flow of delivery and a will to move
            pull requests forward swiftly, the team was ready to implement some
            changes and start improving.
          </p>
          <h3>Kickstarting continuous improvement</h3>
          <p>
            Every team is different. This is why improvements in ways of working
            should consider team context. The teams needed a way to set their
            own goals for success and start working towards them through
            team-specific working agreements.
          </p>
          <p>
            The first thing was to discuss within each team feasible pull
            request cycle time and review time targets. Team cycle time targets
            were set to between 7 and 14 days. Review time targets were set to
            between 1 and 3 days based on a consensus within each team and
            considering industry best practices. Having a target for pull
            request cycle times makes it easier to identify and act on
            exceptions when they emerge.
          </p>
          <p>
            To allow surfacing exceptions to working agreements with a tight
            feedback loop, the teams also enabled daily digests, including a
            daily summary of how the team is doing compared to their working
            agreements.
          </p>
          <ProductScreenshot src={image3} />
          <p>
            To remove the rest of the friction from the pull request process,
            developers also enabled some personal pull request related
            notifications. This ensured they always stay on top of where pull
            requests are going through Slack. For instance, you would know
            instantly when a review is requested from you or when your pull
            request is approved.
          </p>
          <ProductScreenshot src={image4} />
          <p>
            Finally, the team discussed best practices related to code reviews,
            such as what to cover in a code review and how to prepare your code
            to be reviewed. Having common guidelines on conducting code reviews
            increases both the quality and consistency of reviews. Gorgias took
            inspiration from code review best practice material available from
            here.
          </p>
          <h3>Result</h3>
          <p>
            Through a thorough investigation and concrete steps to start
            continuously improving the flow of delivery, Gorgias saw positive
            results across the organization in less than two months. The work
            resulted in a 40% drop in teams&apos; cycle times. Improved flow of
            delivery allows Gorgias to create customer value faster and helps
            grow their business.
          </p>
          <ProductScreenshot src={image5} />
          <QuoteBlock
            quote="With Swarmia we have been able to highlight problem areas, set
            target levels, and drive continuous improvement. In two months, we
            have been able to decrease our teams' cycle times by 40%."
            name="Alex Plugaru"
            title="Co-Founder, CTO"
            photo={alex}
          />
        </>
      }
    />
  )
}
export default GorgiasBody
